import { Locales } from "../../../localization/types";
import ProductPresentationImg from "../ProductPresentation/assets/terminal-presintation.png";
import ProductPresentationImgKz from "../ProductPresentation/assets/terminal-presintation_kz.png";
import ProductPresentationImgBy from "../ProductPresentation/assets/terminal-presintation_by.png";
import {externalLinks} from "../../../pages-data/_V2/common/links";

export const getTerminalMeta = (locale: Locales) => {
	if (locale === 'ru-KZ') {
		return {
			title: 'Кассовая программа для ресторанов: кассовый терминал Quick Resto для работы с заказами в общепите | Казахстан',
			metaTags: [
				{
					name: 'description',
					content:
						'Принимайте заказы на кассе Quick Resto в 3 касания — терминал ориентирован на скорость обслуживания → Простой и понятный интерфейс → Уведомления о сомнительных операциях с чеками защитят ваш бизнес от краж. Автоматизируй бизнес в Казахстане',
				},
				{
					property: 'og:url',
					content: `${externalLinks.quickrestoKZ.href}terminal/`,
				},
				{
					property: 'og:title',
					content:
						'Кассовая программа для ресторанов: кассовый терминал Quick Resto для работы с заказами в общепите | Казахстан',
				},
				{
					property: 'og:description',
					content:
						'Принимайте заказы на кассе Quick Resto в 3 касания — терминал ориентирован на скорость обслуживания → Простой и понятный интерфейс → Уведомления о сомнительных операциях с чеками защитят ваш бизнес от краж. Автоматизируй бизнес в Казахстане',
				},
				{
					property: 'og:type',
					content: 'website',
				},
				{
					property: 'og:image',
					content: `${externalLinks.quickrestoKZ.href}${ProductPresentationImgKz}`,
				},
			],
			canonical: `${externalLinks.quickrestoKZ.href}terminal/`,
		}
	}

	if (locale === 'ru-BY') {
		return {
			title: 'Кассовая программа для ресторанов: кассовый терминал Quick Resto для работы с заказами в общепите | Беларусь',
			metaTags: [
				{
					name: 'description',
					content:
						'Принимай заказы на кассе Quick Resto в 3 касания — терминал ориентирован на скорость обслуживания → Простой и понятный интерфейс → Уведомления о сомнительных операциях с чеками защитят бизнес от краж. Автоматизируй бизнес в Беларуси',
				},
				{
					property: 'og:url',
					content: `${externalLinks.quickrestoBY.href}terminal/`,
				},
				{
					property: 'og:title',
					content:
						'Кассовая программа для ресторанов: кассовый терминал Quick Resto для работы с заказами в общепите | Беларусь',
				},
				{
					property: 'og:description',
					content:
						'Принимай заказы на кассе Quick Resto в 3 касания — терминал ориентирован на скорость обслуживания → Простой и понятный интерфейс → Уведомления о сомнительных операциях с чеками защитят бизнес от краж. Автоматизируй бизнес в Беларуси',
				},
				{
					property: 'og:type',
					content: 'website',
				},
				{
					property: 'og:image',
					content: `${externalLinks.quickrestoBY.href}${ProductPresentationImgBy}`,
				},
			],
			canonical: `${externalLinks.quickrestoBY.href}terminal/`,
		}
	}

	return {
		title: 'Кассовая программа для ресторанов: Кассовый терминал Quick Resto для работы с заказами в общепите',
		metaTags: [
			{
				name: 'description',
				content:
					'Принимайте заказы на кассе Quick Resto в 3 касания — терминал ориентирован на скорость обслуживания → Простой и понятный интерфейс → Уведомления о сомнительных операциях с чеками защитят ваш бизнес от краж',
			},
			{
				property: 'og:url',
				content: `${externalLinks.quickrestoRU.href}terminal/`,
			},
			{
				property: 'og:title',
				content:
					'Кассовая программа для ресторанов: Кассовый терминал Quick Resto для работы с заказами в общепите',
			},
			{
				property: 'og:description',
				content:
					'Принимайте заказы на кассе Quick Resto в 3 касания — терминал ориентирован на скорость обслуживания → Простой и понятный интерфейс → Уведомления о сомнительных операциях с чеками защитят ваш бизнес от краж',
			},
			{
				property: 'og:type',
				content: 'website',
			},
			{
				property: 'og:image',
				content: `${externalLinks.quickrestoRU.href}${ProductPresentationImg}`,
			},
		],
		canonical: `${externalLinks.quickrestoRU.href}terminal/`
	}
}
